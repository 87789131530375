import React, { useContext, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { isEmpty } from 'lodash';

import Header from 'components/layouts/header';
import Sidebar from 'components/layouts/sidebar';
import CustomerServiceAgreementModal from 'components/shared/modals/customer-service-agreement-modal';

import portalRoutes from 'routes/portal';
import { AuthProvider, AuthContext } from 'context/auth-context/auth-context';
import { LookupProvider, LookupContext } from 'context/lookup-context/lookup-context';
import { logHere } from 'helpers/utils';
import { updateProfile } from 'services/profile';
// import { IntercomProvider } from 'react-use-intercom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logHere(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div className="something-wrong">
          <div className="something-wrong--inner">
            <i>
              <svg
                width="84"
                height="62"
                viewBox="0 0 84 62"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M47 30H37V34H47V30Z" fill="#EE5555" />
                <path d="M51 34H47V38H51V34Z" fill="#EE5555" />
                <path d="M37 34H33V38H37V34Z" fill="#EE5555" />
                <path d="M49 16H45V24H49V16Z" fill="#EE5555" />
                <path d="M39 16H35V24H39V16Z" fill="#EE5555" />
                <path
                  d="M81 0H3C1.3 0 0 1.3 0 3V51C0 52.7 1.3 54 3 54H40V58H22C20.9 58 20 58.9 20 60C20 61.1 20.9 62 22 62H40H44H62C63.1 62 64 61.1 64 60C64 58.9 63.1 58 62 58H44V54H81C82.7 54 84 52.7 84 51V3C84 1.3 82.7 0 81 0ZM80 50H44H40H4V4H80V50Z"
                  fill="#EE5555"
                />
              </svg>
            </i>
            <h3>Something went wrong.</h3>
          </div>
        </div>
      );
    }

    return children;
  }
}

// const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
const renderComponents = (
  <Switch>
    {portalRoutes.map((prop) => {
      if (prop.redirect) {
        return <Redirect from={prop.path} to={prop.to} key={prop.key} />;
      }
      return (
        <Route
          path={prop.path}
          exact={prop.exact}
          component={prop.component}
          key={prop.key}
        />
      );
    })}
  </Switch>
);

const Interface = () => {
  const [userDetails, setUserDetails] = useContext(AuthContext);
  const { propertyManagerSettingsLookupObj } = useContext(LookupContext);
  const [isAgreementButtonLoading, setAgreementButtonLoading] = useState(false);

  const agreementHandler = () => {
    const payLoad = {
      property_manager_setting_ids: [
        propertyManagerSettingsLookupObj?.['agree-csa']
          ?.property_manager_setting_id,
      ],
    };
    setAgreementButtonLoading(true);
    updateProfile(payLoad)
      .then(() => {
        setAgreementButtonLoading(false);
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          propertyManagerSettingsObj: {
            ...userDetails?.propertyManagerSettingsObj,
            'agree-csa': propertyManagerSettingsLookupObj?.['agree-csa'],
          },
        }));
      })
      .catch(() => {
        setAgreementButtonLoading(false);
      });
  };

  useEffect(() => {
    if (window?.smartlook) {
      window.smartlook('init', process.env.REACT_APP_SMART_LOOK_ID, {
        region: 'eu',
        advancedNetwork: {
          allowedUrls: [window?.location?.href],
          websockets: true,
        },
      });
      window.smartlook('record', {
        forms: true, numbers: true, emails: false, ips: true,
      });
    }
  }, []);

  useEffect(() => {
    if (window?.smartlook && userDetails?.user_hash) {
      window.smartlook('identify', userDetails?.user_hash, {
        Company: `${userDetails?.partner?.label}`,
        name: `${userDetails?.first_name} ${userDetails?.last_name}`,
      });
    }
  }, [
    userDetails?.first_name,
    userDetails?.last_name,
    userDetails?.partner?.label,
    userDetails?.user_hash,
  ]);

  return (
    <div className="base-container">
      <ErrorBoundary>
        <div
          className="global-content"
          style={
            process.env.REACT_APP_BODY_COLOR
              ? { background: process.env.REACT_APP_BODY_COLOR }
              : {}
          }
        >
          <Sidebar />
          <div className="global-datawrap">
            <Header />
            <div className="global-datawrap__inner">{renderComponents}</div>
            {!isEmpty(userDetails)
            && !userDetails.propertyManagerSettingsObj?.['agree-csa'] ? (
              <CustomerServiceAgreementModal
                agreementHandler={agreementHandler}
                isAgreementButtonLoading={isAgreementButtonLoading}
              />
              ) : (
                ''
              )}
          </div>
        </div>
      </ErrorBoundary>
    </div>
  );
};

const Portal = () => {
  const history = useHistory();
  useEffect(() => {
    const token = localStorage.getItem('userAccessToken');
    if (!token) {
      history.push('/auth');
    }
  }, [history]);

  return (
    <AuthProvider>
      <LookupProvider>
        {/* <IntercomProvider appId={APP_ID}> */}
        <Interface />
        {/* </IntercomProvider> */}
      </LookupProvider>
    </AuthProvider>
  );
};
export default Portal;
