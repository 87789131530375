import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Progress } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import Moment from 'moment';
import NumberFormat from 'react-number-format';

import {
  DashboardHeaderIcon,
  NotificationXlIcon,
  CloseIcon,
  CalendarIcon,
  AnsweringServiceIcon,
  FlatPriceCatalogIcon,
} from 'assets/images/svg-icons';
import DateRangePicker from 'components/shared/form-fields/date-range-picker';
import { getDashboardDetails } from 'services/dashboard';
import ButtonWithLoader from 'components/shared/form-fields/loading-button';
import { AuthContext } from 'context/auth-context/auth-context';
import { LookupContext } from 'context/lookup-context/lookup-context';
import { updateProfile } from 'services/profile';
import JobRating from 'components/shared/job-rating';

const Dashboard = () => {
  const [openJobs, setOpenJobs] = useState();
  const [resolutionTime, setResolutionTime] = useState();
  const [averageRating, setAverageRating] = useState();
  const [jobServiceTypes, setJobServiceTypes] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails, isProfileFetching] = useContext(AuthContext);
  const { propertyManagerSettingsLookupObj } = useContext(LookupContext);
  const startDate = Moment().subtract(29, 'days').format('YYYY-MM-DD');
  const endDate = Moment().format('YYYY-MM-DD');
  const [
    answeringServiceButtonLoading,
    setAnsweringServiceButtonLoading,
  ] = useState(false);
  const [escalationSuccessMessage, setEscalationServiceMessage] = useState(
    false,
  );
  const [
    answeringServiceSuccessMessage,
    setAnsweringServiceSuccessMessage,
  ] = useState(false);
  const [isDashboardDataFetching, setIsDashboardDataFetching] = useState(true);

  const rhfMethods = useForm({
    defaultValues: {
      openJobsDate: {
        selectedRange: {
          startDate: Moment(startDate).toDate(),
          endDate: Moment(endDate).toDate(),
          key: 'selection',
        },
        stepCount: 0,
      },
      resolutionTimeDate: {
        selectedRange: {
          startDate: Moment(startDate).toDate(),
          endDate: Moment(endDate).toDate(),
          key: 'selection',
        },
        stepCount: 0,
      },
      jobServiceTypesDate: {
        selectedRange: {
          startDate: Moment(startDate).toDate(),
          endDate: Moment(endDate).toDate(),
          key: 'selection',
        },
        stepCount: 0,
      },
    },
  });
  const { watch } = rhfMethods;
  const closeNotification = () => {
    const payLoad = {
      property_manager_setting_ids: [
        propertyManagerSettingsLookupObj?.['dismiss-welcome-note']
          ?.property_manager_setting_id,
      ],
    };
    updateProfile(payLoad).then(() => {
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        propertyManagerSettingsObj: {
          ...userDetails?.propertyManagerSettingsObj,
          'dismiss-welcome-note':
            propertyManagerSettingsLookupObj?.['dismiss-welcome-note'],
        },
      }));
    }).catch(() => {});
  };

  const escalationService = useCallback(() => {
    setIsLoading(true);
    const payLoad = {
      property_manager_setting_ids: [
        propertyManagerSettingsLookupObj?.['interested-in-de-escalation']
          ?.property_manager_setting_id,
      ],
    };

    updateProfile(payLoad).then(() => {
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        propertyManagerSettingsObj: {
          ...userDetails?.propertyManagerSettingsObj,
          'interested-in-de-escalation':
            propertyManagerSettingsLookupObj?.['interested-in-de-escalation'],
        },
      }));
      setIsLoading(false);
      setEscalationServiceMessage(true);
    }).catch(() => {});
  }, [propertyManagerSettingsLookupObj, setUserDetails, userDetails?.propertyManagerSettingsObj]);

  const { openJobsDate, resolutionTimeDate, jobServiceTypesDate } = watch();

  const fetchDashboardDetails = (params) => {
    setIsDashboardDataFetching(true);
    getDashboardDetails(params).then((res) => {
      const {
        jobs_by_service_types: jobsByServiceTypes,
        open_jobs: openJobsInfo,
        resolution_time: resolutionTimeInfo,
        average_rating: averageRatingInfo,
      } = res.data;
      if (openJobsInfo) {
        setOpenJobs(openJobsInfo);
      }
      if (resolutionTimeInfo) {
        setResolutionTime(resolutionTimeInfo);
      }
      if (jobsByServiceTypes) {
        setJobServiceTypes(jobsByServiceTypes);
      }
      if (averageRatingInfo) {
        setAverageRating(averageRatingInfo);
      }
      setIsDashboardDataFetching(false);
    }).catch(() => {
      setIsDashboardDataFetching(false);
    });
  };

  useEffect(() => {
    if (openJobsDate?.selectedRange && openJobsDate?.stepCount === 0) {
      const payLoad = {
        type: 'open_jobs',
        from_date: Moment(openJobsDate?.selectedRange?.startDate).format(
          'YYYY-MM-DD',
        ),
        to_date: Moment(openJobsDate?.selectedRange?.endDate).format(
          'YYYY-MM-DD',
        ),
      };
      fetchDashboardDetails(payLoad);
    }
  }, [openJobsDate?.selectedRange, openJobsDate?.stepCount]);

  useEffect(() => {
    if (
      resolutionTimeDate?.selectedRange
      && resolutionTimeDate?.stepCount === 0
    ) {
      let payLoad = {
        type: 'resolution_time',
        from_date: Moment(resolutionTimeDate?.selectedRange?.startDate).format(
          'YYYY-MM-DD',
        ),
        to_date: Moment(resolutionTimeDate?.selectedRange?.endDate).format(
          'YYYY-MM-DD',
        ),
      };
      fetchDashboardDetails(payLoad);
      payLoad = {
        type: 'average_rating',
        from_date: Moment(resolutionTimeDate?.selectedRange?.startDate).format(
          'YYYY-MM-DD',
        ),
        to_date: Moment(resolutionTimeDate?.selectedRange?.endDate).format(
          'YYYY-MM-DD',
        ),
      };
      fetchDashboardDetails(payLoad);
    }
  }, [resolutionTimeDate?.selectedRange, resolutionTimeDate?.stepCount]);

  useEffect(() => {
    if (
      jobServiceTypesDate?.selectedRange
      && jobServiceTypesDate?.stepCount === 0
    ) {
      const payLoad = {
        type: 'jobs_by_service_types',
        from_date: Moment(jobServiceTypesDate?.selectedRange?.startDate).format(
          'YYYY-MM-DD',
        ),
        to_date: Moment(jobServiceTypesDate?.selectedRange?.endDate).format(
          'YYYY-MM-DD',
        ),
      };
      fetchDashboardDetails(payLoad);
    }
  }, [jobServiceTypesDate?.selectedRange, jobServiceTypesDate?.stepCount]);

  useEffect(() => {
    fetchDashboardDetails({
      type: 'potential_savings',
    });
  }, []);

  const answeringServiceHandler = () => {
    setAnsweringServiceButtonLoading(true);
    const payLoad = {
      property_manager_setting_ids: [
        propertyManagerSettingsLookupObj?.[
          'interested-in-after-hours-maintenance-answering-service'
        ]?.property_manager_setting_id,
      ],
    };
    updateProfile(payLoad).then(() => {
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        propertyManagerSettingsObj: {
          ...userDetails?.propertyManagerSettingsObj,
          'interested-in-after-hours-maintenance-answering-service':
            propertyManagerSettingsLookupObj?.[
              'interested-in-after-hours-maintenance-answering-service'
            ],
        },
      }));
      setAnsweringServiceButtonLoading(false);
      setAnsweringServiceSuccessMessage(true);
    }).catch(() => {});
  };

  return (
    <React.Fragment>
      <div className="page-header">
        <span>
          <DashboardHeaderIcon />
        </span>
        <h3>Dashboard</h3>
      </div>
      {userDetails
        && userDetails.propertyManagerSettingsObj
        && !userDetails.propertyManagerSettingsObj['dismiss-welcome-note'] && (
        <div className="notifications-xl" id="notificationXl">
          <div className="notifications-xl--icon">
            <NotificationXlIcon />
          </div>
          <div className="notifications-xl--data">
            <h2>Welcome to Lula Customer Dashboard!</h2>
            <p>
              We will be releasing additional features every couple of months
              and are open to your requests. We&apos;ve built this maintenance
              control center for you so your feedback is welcome.
            </p>
            <p>
              Please visit our
              <Link to="/help"> help page </Link>
              if you need any assistance.
            </p>
          </div>
          <Link
            to="#"
            className="notifications-xl--close"
            onClick={() => closeNotification()}
          >
            <CloseIcon />
          </Link>
        </div>
      )}
      <div className="dashboard-content">

        <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
          <Col
            xs="12"
            lg="6"
            xl="4"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <div className="data-card">
              <div className="data-card--header data-card--header--custom">
                <div>
                  <h3>Open Jobs</h3>
                  <p className="time-range">
                    <span>
                      {Moment(openJobsDate?.selectedRange?.startDate).format(
                        'MMM DD, YYYY',
                      )}
                    </span>
                    &nbsp;to&nbsp;
                    <span>
                      {Moment(openJobsDate?.selectedRange?.endDate).format(
                        'MMM DD, YYYY',
                      )}
                    </span>
                  </p>
                </div>
                <div className="header-filter schedule-date-range">
                  <FormProvider {...rhfMethods}>
                    <DateRangePicker name="openJobsDate" />
                  </FormProvider>
                  <CalendarIcon />
                </div>
              </div>
              <div className="polls">
                {openJobs
                  && openJobs.map((openJob, index) => (
                    <div className="polls-item" key={openJob.label}>
                      <div className="polls-item--question">
                        {openJob.label}
                        {' '}
                        (
                        {openJob.percentage}
                        %):
                        {' '}
                        {openJob.count}
                      </div>
                      <div className="polls-item--progress">
                        <Progress
                          className={`poll-${index + 1}`}
                          value={openJob.percentage}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Col>
          <Col
            xs="12"
            lg="6"
            xl="4"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <div className="data-card">
              <div className="data-card--header data-card--header--custom">
                <div>
                  <h3>Scheduling Time Frame</h3>
                  <p className="time-range">
                    <span>
                      {Moment(
                        resolutionTimeDate?.selectedRange?.startDate,
                      ).format('MMM DD, YYYY')}
                    </span>
                    &nbsp;to&nbsp;
                    <span>
                      {Moment(
                        resolutionTimeDate?.selectedRange?.endDate,
                      ).format('MMM DD, YYYY')}
                    </span>
                  </p>
                </div>
                <div className="header-filter">
                  <FormProvider {...rhfMethods}>
                    <DateRangePicker name="resolutionTimeDate" />
                  </FormProvider>
                  <CalendarIcon />
                </div>
              </div>
              <div className="resolution-time">
                <div className="resolution-time--inner">
                  <Row>
                    <Col md="5" className="text-center">
                      <h4>
                        {resolutionTime?.value}
                        <sup>{resolutionTime?.unit}</sup>
                      </h4>
                    </Col>
                    <Col md="7" className="resolution-time-content">
                      <p>
                        Avg Time from Request
                        <br />
                        to Schedule
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="resolution-time--inner">
                  <Row>
                    <Col md="5" className="text-center">
                      <h4>{averageRating && averageRating.rating}</h4>
                      <div className="star-rating">
                        <JobRating
                          rating={averageRating && averageRating?.rating}
                          width="19"
                          height="18"
                        />
                      </div>
                      <div className="job-count">
                        (
                        {averageRating?.job_count || 0}
                        {' '}
                        jobs)
                      </div>
                    </Col>
                    <Col md="7" className="resolution-time-content">
                      <p>
                        Avg Tenant Satisfaction
                        <br />
                        Rating
                      </p>
                      {Number(averageRating?.rating || 0) > 0 ? (
                        <Link
                          to={{
                            pathname: '/closed-jobs',
                            state: {
                              show_reviewed_jobs: 1,
                              startDate: Moment(
                                resolutionTimeDate?.selectedRange?.startDate,
                              ).format('YYYY-MM-DD'),
                              endDate: Moment(
                                resolutionTimeDate?.selectedRange?.endDate,
                              ).format('YYYY-MM-DD'),
                            },
                          }}
                          className="view-reviews"
                        >
                          View Job Reviews
                        </Link>
                      ) : ''}

                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          {userDetails?.flat_price_catalog
            ? (
              <Col
                xs="12"
                lg="6"
                xl="4"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                <div className="data-card after-hour-maintenance">
                  <FlatPriceCatalogIcon />
                  <h4>
                    Flat Price Catalog
                    <span className="custom-tag new">
                      New
                    </span>
                  </h4>
                  <FormProvider
                    {...rhfMethods}
                    className="d-flex justify-content-center"
                  >
                    <p>
                      Get instant quotes for HVAC, appliances, and water heaters.
                    </p>
                    <a href={userDetails?.flat_price_catalog?.link} target="_blank" rel="noreferrer">
                      <ButtonWithLoader
                        color="primary"
                      >
                        <span>Go to Catalog</span>
                      </ButtonWithLoader>
                    </a>
                  </FormProvider>
                </div>
              </Col>
            ) : (
              <Col
                xs="12"
                lg="6"
                xl="4"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                <div className="data-card with-blur-bg">
                  <div className="potential-savings ">
                    <div className="potential-savings--data">
                      <h2>
                        Want to let go of&nbsp;
                        <br className="d-none d-md-block" />
                        maintenance entirely?
                      </h2>
                      {userDetails
                    && (userDetails.propertyManagerSettingsObj
                    && !userDetails.propertyManagerSettingsObj[
                      'interested-in-de-escalation'
                    ] ? (
                      <div id="escalationService">
                        <h5>Ask about our 24/7 Maintenance Coordination Service.</h5>
                        <FormProvider {...rhfMethods}>
                          <div className="d-flex justify-content-center align-items-center">
                            <ButtonWithLoader
                              isLoading={isLoading}
                              color="primary"
                              onClick={() => escalationService()}
                            >
                              <span>Request More Info</span>
                            </ButtonWithLoader>
                          </div>
                        </FormProvider>
                      </div>
                      ) : (
                        <React.Fragment>
                          {escalationSuccessMessage ? (
                            <div id="escalationServiceMessage" className="d-flex">
                              <p>Request sent. We will be in touch soon.</p>
                            </div>
                          ) : (
                            <p>
                              You sent the request already. If you didn&apos;t get
                              a response after
                              3 days, please contact us at
                              <br />
                              <NumberFormat
                                style={{ color: '#2C91FF' }}
                                displayType="text"
                                value={9133037781}
                                format="(###) ###-####"
                              />
                            </p>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
            )}
          <Col
            xs="12"
            lg="6"
            xl={['regional-24-7', 'national-24-7']?.includes(userDetails?.customer_type) ? '12' : '8'}
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <div className="data-card">
              <div className="data-card--header data-card--header--custom">
                <div>
                  <h3>Jobs by Service Types</h3>
                  <p className="time-range">
                    <span>
                      {Moment(
                        jobServiceTypesDate?.selectedRange?.startDate,
                      ).format('MMM DD, YYYY')}
                    </span>
                    &nbsp;to&nbsp;
                    <span>
                      {Moment(
                        jobServiceTypesDate?.selectedRange?.endDate,
                      ).format('MMM DD, YYYY')}
                    </span>
                  </p>
                </div>
                <div className="header-filter">
                  <FormProvider {...rhfMethods}>
                    <DateRangePicker name="jobServiceTypesDate" />
                  </FormProvider>
                  <CalendarIcon />
                </div>
              </div>
              <div className="job-types">
                <Row>
                  <Col xs="12" xl="4">
                    <div className="job-types-summary">
                      <Row>
                        <Col>
                          <h4>
                            <span>
                              {jobServiceTypes
                                && jobServiceTypes.total_jobs
                                && jobServiceTypes.total_jobs.total}
                            </span>
                            {' '}
                            Total Jobs
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <div className="job-status open">
                            <h5>
                              {jobServiceTypes
                                && jobServiceTypes.total_jobs
                                && jobServiceTypes.total_jobs.open}
                            </h5>
                            <p>Open</p>
                          </div>
                          <div className="job-status closed">
                            <h5>
                              {jobServiceTypes
                                && jobServiceTypes.total_jobs
                                && jobServiceTypes.total_jobs.closed}
                            </h5>
                            <p>Closed</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs="12" xl="8">
                    <ul className="job-details">
                      {jobServiceTypes
                        && jobServiceTypes.service_types
                        && jobServiceTypes.service_types.map((serviceType) => (
                          <li key={serviceType.label}>
                            <span>
                              {serviceType.label}
                              {' '}
                              (
                              {serviceType.count}
                              )
                            </span>
                            {' '}
                            -
                            {' '}
                            {serviceType.percentage}
                            %
                          </li>
                        ))}
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          {!['regional-24-7', 'national-24-7']?.includes(userDetails?.customer_type) && !isDashboardDataFetching && (!isProfileFetching) ? (
            <Col
              xs="12"
              lg="6"
              xl="4"
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <div className="data-card after-hour-maintenance">
                <AnsweringServiceIcon />
                <h4>
                  Need After Hours Maintenance
                  <br className="d-none d-md-block" />
                  Answering Service?
                </h4>
                {userDetails
                && (userDetails.propertyManagerSettingsObj
                && !userDetails.propertyManagerSettingsObj[
                  'interested-in-after-hours-maintenance-answering-service'
                ] ? (
                  <FormProvider
                    {...rhfMethods}
                    className="d-flex justify-content-center"
                  >
                    <p>
                      We provide both customer support and fully-vetted pros!
                    </p>
                    <ButtonWithLoader
                      isLoading={answeringServiceButtonLoading}
                      color="primary"
                      onClick={() => answeringServiceHandler()}
                    >
                      <span>Request More Info</span>
                    </ButtonWithLoader>
                  </FormProvider>
                  ) : (
                    <React.Fragment>
                      {answeringServiceSuccessMessage ? (
                        <div id="escalationServiceMessage" className="d-flex">
                          <p>Request sent. We will be in touch soon.</p>
                        </div>
                      ) : (
                        <p>
                          You sent the request already. If you didn&apos;t get a
                          response after 3 days, please contact us at
                          <br />
                          <NumberFormat
                            style={{ color: '#2C91FF' }}
                            displayType="text"
                            value={9133037781}
                            format="(###) ###-####"
                          />
                        </p>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </Col>
          ) : ''}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
