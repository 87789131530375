import { convertArrayToObject } from 'helpers/utils';
import React, { createContext, useEffect, useState } from 'react';

import { fetchLoggedInUserDetails } from 'services/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState('');
  const [isProfileFetching, setIsProfileFetching] = useState(true);
  useEffect(() => {
    setIsProfileFetching(true);
    fetchLoggedInUserDetails()
      .then((res) => {
        const userData = {
          ...res?.data,
          propertyManagerSettingsObj:
            convertArrayToObject(res?.data?.settings, 'slug'),
        };
        setUserDetails(userData);
        setIsProfileFetching(false);
      }).catch(() => {
        setIsProfileFetching(false);
      });
  }, []);
  return (
    <AuthContext.Provider value={[userDetails, setUserDetails, isProfileFetching]}>
      {children}
    </AuthContext.Provider>
  );
};
